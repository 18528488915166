export const data = [
  {
    title: "Proyectos",
    path: "/proyectos",
    alt: "proyectos",
    url: "https://res.cloudinary.com/jmponz/image/upload/v1674944200/jaime-sese/AP%20Madrid/AP-Madrid-01._mbslxc.jpg",
  },
  {
    title: "Estudio",
    path: "/estudio",
    alt: "proyectos",
    url: "https://res.cloudinary.com/jmponz/image/upload/v1674944391/jaime-sese/B-O-cophenague/B_O-Copenhague-01_qihn01.jpg",
  },
  // {
  //   title: "Servicios",
  //   path: "/servicios",
  //   alt: "proyectos",
  //   url: "https://res.cloudinary.com/jmponz/image/upload/v1674944394/jaime-sese/B-O-cophenague/B_O-Copenhague-05_bjceua.jpg",
  // },
  {
    title: "Publicaciones",
    path: "/publicaciones",
    alt: "proyectos",
    url: "https://res.cloudinary.com/jmponz/image/upload/v1674944166/jaime-sese/AP%20Barcelona/AP-Barcelona-02_o3tgcg.jpg",
  },
  // {
  //     title: 'Mobiliario',
  //     path: '/mobiliario',
  //     cName: 'col-1 col-2',
  //     alt: 'proyectos',
  //     url: 'https://res.cloudinary.com/jmponz/image/upload/v1662113378/jaime-sese/AP%20Madrid/DS8A8369tv1-min_l1h8cj.jpg',

  // },
  {
    title: "Contacto",
    path: "/contacto",
    alt: "proyectos",
    url: "https://res.cloudinary.com/jmponz/image/upload/v1674944536/jaime-sese/Skinpharmacy/skin-pharmacy-02_cfkp19.jpg",
  },
]