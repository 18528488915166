import "./Navbar.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import { IconContext } from "react-icons";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  // const [menuHome, setMenuHome] = useState(false)

  // const changeMenuHomeColor = () => {
  //     if (window.scrollY >= 560) {
  //         setMenuHome(true)
  //     } else {
  //         setMenuHome(false)
  //     }
  // }

  // window.addEventListener('scroll', changeMenuHomeColor)

  return (
    <>
      <article className="nav-header">
        <IconContext.Provider value={{}}>
          <nav className="navbar-home-active">
            <ul>
              <li className="menu-bars-home-active">
                <Link to="#">
                  <FaIcons.FaBars onClick={showSidebar} />
                </Link>
              </li>
              <li className="nav-logo-home-active">
                <Link to="/">
                  <p>
                    JAIME<strong>SESÉ</strong>ARQUITECTURA
                  </p>
                </Link>
              </li>
            </ul>
          </nav>

          <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
            <ul className="nav-menu-items" onClick={showSidebar}>
              <li className="navbar-toggle">
                <Link to="#" className="menu-bars-close">
                  <AiIcons.AiOutlineClose />
                </Link>
              </li>
              {SidebarData.map((item, index) => {
                return (
                  <li key={index} className={item.cName}>
                    <Link to={item.path}>
                      {item.icon}
                      <span>{item.title}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </IconContext.Provider>
      </article>
    </>
  );
}

export default Navbar;
