import React from "react";
import { useState } from "react";
import "./CookieBanner.css";
import posthog from "posthog-js";

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  const acceptCookies = () => {
    posthog.opt_in_capturing();
    setShowBanner(false);
  };

  const declineCookies = () => {
    posthog.opt_out_capturing();
    setShowBanner(false);
  };

  return (
    <div>
      {showBanner && (
        <div className="cookie-banner">
          <div className="cookie-banner-text">
            <p>
              Con su acuerdo, nosotros usamos cookies o tecnologías similares
              para almacenar, acceder y procesar datos personales como su visita
              en este sitio web.
            </p>
          </div>
          <div className="cookie-banner-buttons">
            <span onClick={acceptCookies}>aceptar</span>
            <span onClick={declineCookies}>declinar</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CookieBanner;
