import "./Contacto.css";
import { useEffect } from "react";
import React from "react";

const Contacto = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section className="contacto-section">
        <article className="contacto-article">
        {/* <h1>CONTACTO</h1> */}
          <p>Madrid | Zaragoza | Barcelona</p>
          <p className="contacto-separador"></p>
          <p>estudio@jaimesesearquitectura.com</p>
        </article>
      </section>
    </div>
  );
};

export default Contacto;
