export const data = [
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944173/jaime-sese/AP%20Barcelona/AP-Barcelona-20_egsmfi.jpg",
    title: "Audemars Piguet Barcelona",
    subtitle: "Paseo de Gracia",
    path: "/proyectos/audemars-piguet-barcelona",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944197/jaime-sese/AP%20Madrid/AP-Madrid-04_qa3mvp.jpg",
    title: "Audemars Piguet Madrid",
    subtitle: "Serrano",
    path: "/proyectos/audemars-piguet-madrid",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944218/jaime-sese/AP%20Marbella/AP-Marbella-03_j0fpjx.jpg",
    title: "Audemars Piguet Marbella",
    subtitle: "El Corte Inglés | Puerto Banús",
    path: "/proyectos/audemars-piguet-marbella",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944443/jaime-sese/AP%20Serrano%2066/ap-serrano-02_txbmdl.jpg",
    title: "Showroom Madrid",
    subtitle: "Serrano",
    path: "/proyectos/atico-serrano-66",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944392/jaime-sese/B-O-cophenague/B_O-Copenhague-04_w96mv2.jpg",
    title: "B&O Cophenague",
    path: "/proyectos/bo-copenhague",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944251/jaime-sese/Best%20of%20You%20Serrano/Best-of-you-01_ebenfj.jpg",
    title: "Best Of You Madrid",
    subtitle: "Serrano",
    path: "/proyectos/best-of-you-serrano",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944534/jaime-sese/Skinpharmacy/skin-pharmacy-05_kmz4yi.jpg",
    title: "Skinpharmacy Jorge Juan 34",
    subtitle: "Madrid",
    path: "/proyectos/skinpharmacy-jorge-juan",
  },
  // {
  //   link: "https://res.cloudinary.com/jmponz/image/upload/v1660304163/jaime-sese/AP%20Barcelona/_LLO0612-min_py3iyu.jpg",
  //   title: "Gestoría Ofigestión",
  //   subtitle: "Barbastro",
  //   path: "/proyectos/gestoria-ofigestion",
  // },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944485/jaime-sese/Pizzico/pizzico-04_eoav9z.jpg",
    title: "Pizzico Gelatique",
    subtitle: "Mercado de la Paz | Madrid",
    path: "/proyectos/pizzico-gelatique",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944421/jaime-sese/Gallino/gallino-05_hl0iku.jpg",
    title: "Gallino",
    subtitle: "Mercado de la Paz | Madrid",
    path: "/proyectos/gallino",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944467/jaime-sese/Oficina%20TREA/trea-07_lvngcj.jpg",
    title: "Oficina TREA",
    subtitle: "Ortega y Gasset 20",
    path: "/proyectos/oficina-trea",
  },
  // {
  //   link: "https://res.cloudinary.com/jmponz/image/upload/v1660304163/jaime-sese/AP%20Barcelona/_LLO0612-min_py3iyu.jpg",
  //   title: "Oficinas AP Serrano 66",
  //   path: "/proyectos/oficinas-serrano",
  // },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944517/jaime-sese/Restaurante%20FIJO/fijo-03_r9qen7.jpg",
    title: "Restaurante Fijo",
    subtitle: "Infanta Mercedes",
    path: "/proyectos/restaurante-fijo",
  },
  // {
  //   link: "https://res.cloudinary.com/jmponz/image/upload/v1660304163/jaime-sese/AP%20Barcelona/_LLO0612-min_py3iyu.jpg",
  //   title: "Vivienda Andy & Eva",
  //   path: "/proyectos/vivienda-andy-eva",
  // },
];
