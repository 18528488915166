export const data = [
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944252/jaime-sese/Best%20of%20You%20Serrano/Best-of-you-03_gxi75f.jpg",
    text: "Audemars Piguet Barcelona",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944251/jaime-sese/Best%20of%20You%20Serrano/Best-of-you-01_ebenfj.jpg",
    text: "Audemars Piguet Madrid",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944249/jaime-sese/Best%20of%20You%20Serrano/Best-of-you-02_bielei.jpg",
    text: "3",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944249/jaime-sese/Best%20of%20You%20Serrano/Best-of-you-04_phnusk.jpg",
    text: "4",
  },
];
