export const data = [
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944221/jaime-sese/AP%20Marbella/AP-Marbella-06_mesqgu.jpg",
    text: "Audemars Piguet Barcelona",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944220/jaime-sese/AP%20Marbella/AP-Marbella-02_hu5ydm.jpg",
    text: "3",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944219/jaime-sese/AP%20Marbella/AP-Marbella-05_nr8ecw.jpg",
    text: "4",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944218/jaime-sese/AP%20Marbella/AP-Marbella-03_j0fpjx.jpg",
    text: "5",
  },
  // {
  //   link: "https://res.cloudinary.com/jmponz/image/upload/v1674944217/jaime-sese/AP%20Marbella/AP-Marbella-04_d2lofb.jpg",
  //   text: "6",
  // },
];
