export const data = [
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944174/jaime-sese/AP%20Barcelona/AP-Barcelona-13_y27s1x.jpg",
    text: "3",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944173/jaime-sese/AP%20Barcelona/AP-Barcelona-11_xz0vlg.jpg",
    text: "4",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944173/jaime-sese/AP%20Barcelona/AP-Barcelona-20_egsmfi.jpg",
    text: "5",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944173/jaime-sese/AP%20Barcelona/AP-Barcelona-06_yqeuwa.jpg",
    text: "6",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944171/jaime-sese/AP%20Barcelona/AP-Barcelona-03_zm9ubu.jpg",
    text: "7",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944171/jaime-sese/AP%20Barcelona/AP-Barcelona-05_t4dj1r.jpg",
    text: "8",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944170/jaime-sese/AP%20Barcelona/AP-Barcelona-14_wodbcp.jpg",
    text: "9",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944169/jaime-sese/AP%20Barcelona/AP-Barcelona-16_ffneo9.jpg",
    text: "7",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944167/jaime-sese/AP%20Barcelona/AP-Barcelona-07_cdbdcu.jpg",
    text: "7",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944167/jaime-sese/AP%20Barcelona/AP-Barcelona-01_vnimwl.jpg",
    text: "7",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944166/jaime-sese/AP%20Barcelona/AP-Barcelona-02_o3tgcg.jpg",
    text: "7",
  },
];
