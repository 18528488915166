import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { BrowserRouter as Router } from "react-router-dom"
import posthog from "posthog-js";

posthog.init("phc_qW8QB2P8A3isGu5PoMMTVjAiVG7f1uUHneHIklwMIJK", {
  api_host: "https://app.posthog.com",
});

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <Router>
    <App />
  </Router>
)
