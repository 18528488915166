import "./App.css";
import React from "react";
import AppRoutes from "./routes/AppRoutes";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import CookieBanner from "./components/CookieBanner/CookieBanner";
import posthog from "posthog-js";

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <AppRoutes />
        {posthog.has_opted_in_capturing() ||
        posthog.has_opted_out_capturing() ? null : (
          <CookieBanner />
        )}
      <Footer />
    </div>
  );
};

export default App;
