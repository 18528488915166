import "./HomeLinks.css";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { data } from "./HomeLinksData";
import Aos from "aos";
import "aos/dist/aos.css";

const HomeLinks = () => {
  useEffect(() => {
    Aos.init({ duration: 200 });
    window.scrollTo(0, 0);
  }, []);

  return (
      <section className="container_home">
        {data.map((item, index) => (
          <Link to={item.path} key={index}>
            <article
              className="row_home"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              <img src={item.url} alt={item.alt}></img>
              <div className="image_home_overlay">
                <h1 className="image_home_title">{item.title}</h1>
              </div>
            </article>
          </Link>
        ))}
      </section>
  );
};

export default HomeLinks;
