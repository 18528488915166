import "./ProyectosLinks.css";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { data } from "./ProyectosLinksData";
import Aos from "aos";
import "aos/dist/aos.css";

const ProyectosLinks = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="row-projects">
      {data.map((item, index) => (
        <Link to={item.path} key={index}>
          <div
            className="image-projects"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <img
              className="image-projects-img"
              alt="foto"
              src={item.link}
            ></img>
            <div className="image-projects-overlay">
              <p className="image-projects-title">{item.title}</p>
              <p className="image-projects-subtitle">{item.subtitle}</p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default ProyectosLinks;
