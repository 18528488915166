import React, { useEffect } from "react";
import "./Estudio.css";

const Estudio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="estudio-section">
        <h1>ESTUDIO</h1>
        <article className="estudio-article">
          <div className="estudio-text">
            <p>
              Jaime Sesé Arquitectura nace tras una larga trayectoria
              profesional en el mundo de la arquitectura e interiorismo
              colaborando, ejecutando y dirigiendo proyectos de gran envergadura
              en el mundo de retail, hotelero y residencial en todo el ámbito
              Nacional, con especial hincapié en Barcelona, Marbella y Madrid.
              El equipo de Jaime Sesé Arquitectura está formado por arquitectos,
              interioristas, paisajistas e ingenieros donde nuestra única
              finalidad es hacer un trabajo de calidad tanto en el diseño
              arquitectonico como en la ejecución de obra diferenciamos del
              resto de estudios por tener un detalle de acabado riguroso.
            </p>
          </div>
          <div className="estudio-image">
            <img alt="estudio" src="https://res.cloudinary.com/jmponz/image/upload/v1674944391/jaime-sese/B-O-cophenague/B_O-Copenhague-01_qihn01.jpg"></img>
          </div>
        </article>
      </section>
    </>
  );
};

export default Estudio;
