export const data = [
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944486/jaime-sese/Pizzico/pizzico-02_cxnczh.jpg",
    text: "Audemars Piguet Barcelona",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944486/jaime-sese/Pizzico/pizzico-05_snxehl.jpg",
    text: "Audemars Piguet Madrid",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944486/jaime-sese/Pizzico/pizzico-01_qbilek.jpg",
    text: "3",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944485/jaime-sese/Pizzico/pizzico-04_eoav9z.jpg",
    text: "4",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944485/jaime-sese/Pizzico/pizzico-03_ypjcgq.jpg",
    text: "5",
  },
];
