export const SidebarData = [
  {
    title: "Home",
    path: "/",
    cName: "nav-text",
  },
  {
    title: "Proyectos",
    path: "/proyectos",
    cName: "nav-text",
  },
  {
    title: "Estudio",
    path: "/estudio",
    cName: "nav-text",
  },
  // {
  //   title: "Servicios",
  //   path: "/servicios",
  //   cName: "nav-text",
  // },
  // {
  //   title: "Mobiliario",
  //   path: "/mobiliario",
  //   cName: "nav-text",
  // },
  {
    title: "Publicaciones",
    path: "/publicaciones",
    cName: "nav-text",
  },
  {
    title: "Clientes",
    path: "/clientes",
    cName: "nav-text",
  },
  {
    title: "Contacto",
    path: "/contacto",
    cName: "nav-text",
  },
];
