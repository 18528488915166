export const data = [
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944421/jaime-sese/Gallino/gallino-05_hl0iku.jpg",
    text: "Audemars Piguet Barcelona",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944420/jaime-sese/Gallino/gallino-01_w7vwnt.jpg",
    text: "Audemars Piguet Madrid",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944420/jaime-sese/Gallino/gallino-02_aywcc0.jpg",
    text: "3",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944420/jaime-sese/Gallino/gallino-04_oq4onu.jpg",
    text: "4",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944419/jaime-sese/Gallino/gallino-03_qw42xz.jpg",
    text: "5",
  },
];
