export const data = [
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944200/jaime-sese/AP%20Madrid/AP-Madrid-11_awvwxv.jpg",
    text: "1",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944200/jaime-sese/AP%20Madrid/AP-Madrid-01._mbslxc.jpg",
    text: "2",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944200/jaime-sese/AP%20Madrid/AP-Madrid-07_jht5lf.jpg",
    text: "3",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944199/jaime-sese/AP%20Madrid/AP-Madrid-06_wfp1en.jpg",
    text: "4",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944197/jaime-sese/AP%20Madrid/AP-Madrid-02_sv0h5m.jpg",
    text: "5",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944197/jaime-sese/AP%20Madrid/AP-Madrid-04_qa3mvp.jpg",
    text: "6",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944197/jaime-sese/AP%20Madrid/AP-Madrid-08_xhfiwn.jpg",
    text: "7",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944195/jaime-sese/AP%20Madrid/AP-Madrid-05_ey8yoi.jpg",
    text: "4",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944195/jaime-sese/AP%20Madrid/AP-Madrid-10_o1yheg.jpg",
    text: "5",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944194/jaime-sese/AP%20Madrid/AP-Madrid-03_au2cpd.jpg",
    text: "6",
  },
];
