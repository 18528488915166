export const data = [
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944517/jaime-sese/Restaurante%20FIJO/fijo-03_r9qen7.jpg",
    text: "Audemars Piguet Barcelona",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944517/jaime-sese/Restaurante%20FIJO/fijo-07_kphlzx.jpg",
    text: "Audemars Piguet Madrid",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944516/jaime-sese/Restaurante%20FIJO/fijo-05_cqt81x.jpg",
    text: "3",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944516/jaime-sese/Restaurante%20FIJO/fijo-02_pwhit1.jpg",
    text: "4",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944515/jaime-sese/Restaurante%20FIJO/fijo-06_d9rule.jpg",
    text: "5",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944515/jaime-sese/Restaurante%20FIJO/fijo-04_klc72u.jpg",
    text: "6",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944513/jaime-sese/Restaurante%20FIJO/fijo-01_ru2akf.jpg",
    text: "7",
  },
];
