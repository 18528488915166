import { Routes, Route } from "react-router-dom"
import React, { useEffect } from "react";
import HomePage from "../pages/HomePage/HomePage"
import Estudio from "../pages/Estudio/Estudio"
// import Mobiliario from "../pages/Mobiliario/Mobiliario"
import Proyectos from "../pages/Proyectos/Proyectos"
import Publicaciones from "../pages/Publicaciones/Publicaciones"
import Servicios from "../pages/Servicios/Servicios"
import Contacto from "../pages/Contacto/Contacto"
import ApBcnPage from "../pages/Proyectos/ApBcnPage/ApBcnPage"
import ApMadPage from "../pages/Proyectos/ApMadPage/ApMadPage"
import ApMarbellaPage from "../pages/Proyectos/ApMarbellaPage/ApMarbellaPage"
import AticoSerranoPage from "../pages/Proyectos/AticoSerranoPage/AticoSerranoPage"
import BoCopenhaguePage from "../pages/Proyectos/BoCopenhague/BoCopenhague"
import BestOfYouSerranoPage from "../pages/Proyectos/BestOfYouSerranoPage/BestOfYouSerranoPage"
import SkinpharmacyPage from "../pages/Proyectos/SkinpharmacyPage/SkinpharmacyPage"
// import GestoriaPage from "../pages/Proyectos/GestoriaPage/GestoriaPage"
import PizzicoPage from "../pages/Proyectos/PizzicoPage/PizzicoPage"
import GallinoPage from "../pages/Proyectos/GallinoPage/GallinoPage"
import OficinaTreaPage from "../pages/Proyectos/OficinaTreaPage/OficinaTreaPage"
// import OficinasSerranoPage from "../pages/Proyectos/OficinasSerranoPage/OficinasSerranoPage"
import RestauranteFijoPage from "../pages/Proyectos/RestauranteFijoPage/RestauranteFijoPage"
// import ViviendaPage from "../pages/Proyectos/ViviendaPage/ViviendaPage"
import Clientes from "../pages/Clientes/Clientes"

const AppRoutes = () => {
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/estudio" element={<Estudio />} />
            {/* <Route path="/mobiliario" element={<Mobiliario />} /> */}
            <Route path="/proyectos" element={<Proyectos />} />
            <Route path="/proyectos/audemars-piguet-barcelona" element={<ApBcnPage />} />
            <Route path="/proyectos/audemars-piguet-madrid" element={<ApMadPage />} />
            <Route path="/proyectos/audemars-piguet-marbella" element={<ApMarbellaPage />} />
            <Route path="/proyectos/atico-serrano-66" element={<AticoSerranoPage />} />
            <Route path="/proyectos/bo-copenhague" element={<BoCopenhaguePage />} />
            <Route path="/proyectos/best-of-you-serrano" element={<BestOfYouSerranoPage />} />
            <Route path="/proyectos/skinpharmacy-jorge-juan" element={<SkinpharmacyPage />} />
            {/* <Route path="/proyectos/gestoria-ofigestion" element={<GestoriaPage />} /> */}
            <Route path="/proyectos/pizzico-gelatique" element={<PizzicoPage />} />
            <Route path="/proyectos/gallino" element={<GallinoPage />} />
            <Route path="/proyectos/oficina-trea" element={<OficinaTreaPage />} />
            {/* <Route path="/proyectos/oficinas-serrano" element={<OficinasSerranoPage />} /> */}
            <Route path="/proyectos/restaurante-fijo" element={<RestauranteFijoPage />} />
            {/* <Route path="/proyectos/vivienda-andy-eva" element={<ViviendaPage />} /> */}
            <Route path="/publicaciones" element={<Publicaciones />} />
            <Route path="/servicios" element={<Servicios />} />
            <Route path="/clientes" element={<Clientes />} />
            <Route path="/contacto" element={<Contacto />} />
        </Routes>
    )
}

export default AppRoutes