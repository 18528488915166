export const data = [
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944537/jaime-sese/Skinpharmacy/skin-pharmacy-03_jwnm8w.jpg",
    text: "Audemars Piguet Barcelona",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944536/jaime-sese/Skinpharmacy/skin-pharmacy-02_cfkp19.jpg",
    text: "Audemars Piguet Madrid",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944536/jaime-sese/Skinpharmacy/skin-pharmacy-04_lzxcoh.jpg",
    text: "3",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944536/jaime-sese/Skinpharmacy/skin-pharmacy-01_em1qos.jpg",
    text: "4",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944534/jaime-sese/Skinpharmacy/skin-pharmacy-07_wxcott.jpg",
    text: "5",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944534/jaime-sese/Skinpharmacy/skin-pharmacy-06_epgead.jpg",
    text: "6",
  },
];
