export const data = [
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944443/jaime-sese/AP%20Serrano%2066/ap-serrano-02_txbmdl.jpg",
    text: "Audemars Piguet Barcelona",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944443/jaime-sese/AP%20Serrano%2066/ap-serrano-03_llzz8z.jpg",
    text: "Audemars Piguet Madrid",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944442/jaime-sese/AP%20Serrano%2066/ap-serrano-04_wn8yb1.jpg",
    text: "3",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944441/jaime-sese/AP%20Serrano%2066/ap-serrano-01_v3f9pm.jpg",
    text: "4",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944441/jaime-sese/AP%20Serrano%2066/ap-serrano-05_ctuc9m.jpg",
    text: "5",
  },
];
