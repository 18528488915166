import "./Proyectos.css";
import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import ProyectosLinks from "../../components/ProyectosLinks/ProyectosLinks";

const Proyectos = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <section className="projects-section">
        <article className="projects-article">
          <h1>PROYECTOS</h1>
        </article>
      </section>
      <ProyectosLinks></ProyectosLinks>
    </>
  );
};

export default Proyectos;
