import { data } from "./AticoSerranoData";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const AticoSerranoPage = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleImageClick = (index) => {
    setModalOpen(true);
    setSelectedImageIndex(index);
    document.body.style.overflow = "hidden";
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedImageIndex(0);
    document.body.style.overflow = "auto";
  };

  const handlePreviousImage = () => {
    if (selectedImageIndex !== 0) setSelectedImageIndex(selectedImageIndex - 1);
  };

  const handleNextImage = () => {
    if (selectedImageIndex !== data.length - 1)
      setSelectedImageIndex(selectedImageIndex + 1);
  };

  return (
    <>
      <article className="container-gallery-img">
        <div className="projects-gallery-section">
          <div className="projects-article">
            <h2>Showroom Madrid</h2>
            <h4>Serrano</h4>
          </div>
        </div>
        {data.map((item, index) => (
          <div
            className="category-image"
            key={index}
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <img
              src={item.link}
              alt={item.text}
              onClick={() => handleImageClick(index)}
            ></img>
          </div>
        ))}
        {modalOpen && (
          <div className="modal-container">
            <button className="btn-close" onClick={handleModalClose}>
              X
            </button>
            <button className="btn-previous" onClick={handlePreviousImage}>
              Previous
            </button>
            <button className="btn-next" onClick={handleNextImage}>
              Next
            </button>
            <div className="modal-content">
              <img
                src={data[selectedImageIndex].link}
                alt={data[selectedImageIndex].text}
              />
              {/* <div className="modal-caption">
                {data[selectedImageIndex].text}
              </div> */}
            </div>
          </div>
        )}
      </article>
      <div className="projects-pagination">
        <Link
          to="/proyectos/audemars-piguet-marbella"
          className="projects-pagination-prev"
        >
          Audemars Piguet Marbella
        </Link>
        <Link
          to="/proyectos/bo-copenhague"
          className="projects-pagination-next"
        >
          B&O Cophenague
        </Link>
      </div>
    </>
  );
};
export default AticoSerranoPage;
