import "./Clientes.css";
import { useEffect } from "react";
import React from "react";

const ClientesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="clientes-section">
        <h1>CLIENTES</h1>
        <article className="clientes-article">
          {/* <p>
            Los espacios diseñados por Jaime Sesé Arquitectura han sido
            publicados en varias de las revistas mas prestigiosas de
            Arquitectura e interiorismo de España, entre las que destacan las
            revistas Icon y AD -Architectural Digest- España con un amplio
            reportaje del nuevo espacio de Audemars Piguet Madrid en 2019 y
            Audemars Piguet Barcelona en 2020.
          </p> */}
          <div className="clientes-links">
            <ul>
              <li>
                <a
                  href="https://www.audemarspiguet.com/com/es/home.html?cq_src=google_ads&cq_cmp=12662897937&cq_con=121742785833&cq_term=audemars&cq_med=&cq_plac=&cq_net=g&cq_pos=&cq_plt=gp&gclid=CjwKCAiA-dCcBhBQEiwAeWidtUrHmuJ6eCHY3ygupQNk2IIGpfKHfH-0ua0uITgQb2XrwGXX1IwvvxoCDkEQAvD_BwE&gclsrc=aw.ds"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/Audemars_Piguet_logo.png/1599px-Audemars_Piguet_logo.png"
                    alt="audemars-piguet"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://dkv.es/particulares"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/DKV_%28Versicherung%29_logo.svg/1599px-DKV_%28Versicherung%29_logo.svg.png"
                    alt="dkv"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://treaam.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://treaam.com/wp-content/uploads/2019/08/TR3A-logo-notxt.png"
                    alt="tr3a"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.bang-olufsen.com/es/us"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    class="clientes-links-bo"
                    src="https://images.ctfassets.net/8cd2csgvqd3m/3o6RN06GNoDMAyJhVqftSZ/4822ba988465f56310bddec0f4151bd2/B_O_Black.svg"
                    alt="b&o"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.arquitecturaydiseno.es/estilo-de-vida/audemars-piguet-estrena-barcelona-espacio-encuentro-arte-artesania-y-cultura_3808"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://www.arquitecturaydiseno.es/Content/skins/ayd2019/img/logo-ayd-negro-rojo.svg"
                    alt="arquitectura-diseno"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://barcelonapaseodegracia.com/es/miembros/audemars-piguet-house-barcelona/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://res.cloudinary.com/jmponz/image/upload/v1663170324/jaime-sese/logos/passeig_logo_absigz.png"
                    alt="passeig-gracia"
                  ></img>
                </a>
              </li>
            </ul>
          </div>
        </article>
      </section>
    </>
  );
};

export default ClientesPage;
