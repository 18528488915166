export const data = [
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944395/jaime-sese/B-O-cophenague/B_O-Copenhague-06_ln6mjr.jpg",
    text: "Audemars Piguet Barcelona",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944394/jaime-sese/B-O-cophenague/B_O-Copenhague-05_bjceua.jpg",
    text: "Audemars Piguet Madrid",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944392/jaime-sese/B-O-cophenague/B_O-Copenhague-04_w96mv2.jpg",
    text: "3",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944391/jaime-sese/B-O-cophenague/B_O-Copenhague-02_gv61qa.jpg",
    text: "4",
  },
];
