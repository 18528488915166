import "./HomePage.css";
import HomeLinks from "../../components/HomeLinks/HomeLinks";
import Slider from "../../components/Slider/Slider";
import React, { useEffect } from "react";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Slider></Slider>
      <HomeLinks></HomeLinks>
    </>
  );
};

export default HomePage;
