export const data = [
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944467/jaime-sese/Oficina%20TREA/trea-07_lvngcj.jpg",
    text: "Audemars Piguet Barcelona",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944467/jaime-sese/Oficina%20TREA/trea-06_rw8a6d.jpg",
    text: "Audemars Piguet Madrid",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944466/jaime-sese/Oficina%20TREA/trea-04_apfqxg.jpg",
    text: "3",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944466/jaime-sese/Oficina%20TREA/trea-05_xzwuvl.jpg",
    text: "4",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944465/jaime-sese/Oficina%20TREA/trea-02_udt54j.jpg",
    text: "5",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944465/jaime-sese/Oficina%20TREA/trea-10_xyithc.jpg",
    text: "6",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944464/jaime-sese/Oficina%20TREA/trea-03_qtfh0o.jpg",
    text: "7",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944463/jaime-sese/Oficina%20TREA/trea-08_qqqdnq.jpg",
    text: "7",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944464/jaime-sese/Oficina%20TREA/trea-09_gootup.jpg",
    text: "7",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944463/jaime-sese/Oficina%20TREA/trea-01_q1wxdo.jpg",
    text: "7",
  },
];
