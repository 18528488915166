import React, { useEffect, useState } from "react";
import { data } from "./SliderData";
import './Slider.css'

const Slider = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSlideIndex((slideIndex + 1) % data.length);
    }, 3000);
    return () => clearInterval(intervalId);
  }, [slideIndex, data.length]);

  return (
    <div className="slider-home">
      <img src={data[slideIndex].link} alt={data[slideIndex].text} />
    </div>
  );
};

export default Slider;
