import "./Footer.css";
import { BsLinkedin, BsInstagram } from "react-icons/bs";
import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <hr className="hr-footer"></hr>
        <div className="container-footer">
          <div className="footer-logo">
            <img
              className="footer-logo-img"
              src="https://res.cloudinary.com/jmponz/image/upload/v1680123622/jaime-sese/logos/logo_nuevo_siqkz2.png"
              alt="jaime_sese_logo"
            />
          </div>
          <div className="footer-text">
            <p>CONTACTO</p>
            <p>667.401.879</p>
            <div className="social-icons">
              <li>
                <a
                  href="https://www.instagram.com/jaimesese_arquitectura/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsInstagram />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/jaime-ses%C3%A9-masgrau-a2a23a33/?originalSubdomain=es"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <BsLinkedin />
                </a>
              </li>
            </div>
          </div>
          <div className="footer-text-right">
            <li>
              <p>ESTUDIO</p>
            </li>
            <li>
              <p>ARQUITECTURA</p>
            </li>
            <li>
              <p>INTERIORISMO</p>
            </li>
            <li>
              <p>DISEÑO</p>
            </li>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
