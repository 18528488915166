import Navbar from "../../components/Navbar/Navbar";
import "./Publicaciones.css";
import React from "react";

const Publicaciones = () => {
  return (
    <>
      <Navbar></Navbar>
      <section className="publicaciones-section">
        <h1>PUBLICACIONES</h1>
        <article className="publicaciones-article">
          <p>
            Los espacios diseñados por Jaime Sesé Arquitectura han sido
            publicados en varias de las revistas mas prestigiosas de
            Arquitectura e interiorismo de España, entre las que destacan las
            revistas Icon y AD -Architectural Digest- España con un amplio
            reportaje del nuevo espacio de Audemars Piguet Madrid en 2019 y
            Audemars Piguet Barcelona en 2020.
          </p>
          <div className="publicaciones-links">
            <ul>
              <li>
                <a
                  href="https://www.eleconomista.es/status/noticias/10509943/04/20/Audemars-Piguet-abre-una-nueva-tienda-en-el-corazon-de-Barcelona.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://s03.s3c.es/imag3/logos/economista/svg/economistaBO-amp2.svg"
                    alt="el-economista"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://forbes.es/forbes-watches/62773/audemars-piguet-abre-su-ap-house-en-el-passeig-de-gracia-de-barcelona/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/db/Forbes_logo.svg/1024px-Forbes_logo.svg.png"
                    alt="forbes"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://watch-test.com/audemars-piguet/audemars-piguet-abre-una-nueva-ap-house-en-barcelona/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://res.cloudinary.com/jmponz/image/upload/v1663170324/jaime-sese/logos/watch_test_logo_anclxk.png"
                    alt="watch-test"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lavanguardia.com/economia/20200330/474184535606/ap-house-nuevo-punto-encuentro-barcelona-esta-passeig-gracia.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://www.ignaciosilvestre.com/wp-content/uploads/2017/10/vanguardia-logo.png"
                    alt="la-vanguardia"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://barcelonapaseodegracia.com/es/miembros/audemars-piguet-house-barcelona/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://res.cloudinary.com/jmponz/image/upload/v1663170324/jaime-sese/logos/passeig_logo_absigz.png"
                    alt="passeig-gracia"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.marca.com/tiramillas/lujo/2018/10/17/5bc77a1ce2704e94038b4600.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="http://www.bluepad.es/wp-content/uploads/2016/06/Marca-logo.png"
                    alt="marca"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://diariodesign.com/2020/09/audemars-piguet-da-un-giro-al-concepto-boutique-de-tienda-a-hogar/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://www.lafabrica.com/madriddesignfestival/wp-content/uploads/2020/12/Diariodesign-logotipo-byn.png"
                    alt="diario-design"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.merca2.es/2020/04/04/audemars-piaget-house-barcelona-311576/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://www.grupomerca2.com/images/merca2n.png"
                    alt="merca-2"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.bulevard.es/wp-content/uploads/2019/12/Logotipo-magazine-BULEVARD-V1_01_400x150_Gris.png"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://www.bulevard.es/wp-content/uploads/2019/12/Logotipo-magazine-BULEVARD-V1_01_400x150_Gris.png"
                    alt="bulevard-magazine"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://elpais.com/elpais/2018/10/02/icon/1538473264_788298.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/El_Pais_logo_2007.svg/2560px-El_Pais_logo_2007.svg.png"
                    alt="el-pais"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.metropoliabierta.com/economia/guerra-titanes-paseo-gracia_23193_102.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://res.cloudinary.com/jmponz/image/upload/v1663170324/jaime-sese/logos/metropoli_logo_bxj7jc.png"
                    alt="metropoli"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.arquitecturaydiseno.es/estilo-de-vida/audemars-piguet-estrena-barcelona-espacio-encuentro-arte-artesania-y-cultura_3808"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://www.arquitecturaydiseno.es/Content/skins/ayd2019/img/logo-ayd-negro-rojo.svg"
                    alt="arquitectura-diseno"
                  ></img>
                </a>
              </li>
            </ul>
          </div>
        </article>
      </section>
    </>
  );
};

export default Publicaciones;
