export const data = [
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944395/jaime-sese/B-O-cophenague/B_O-Copenhague-06_ln6mjr.jpg",
    text: "3",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944173/jaime-sese/AP%20Barcelona/AP-Barcelona-20_egsmfi.jpg",
    text: "5",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944392/jaime-sese/B-O-cophenague/B_O-Copenhague-03_zlmlnl.jpg",
    text: "6",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944171/jaime-sese/AP%20Barcelona/AP-Barcelona-03_zm9ubu.jpg",
    text: "7",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944171/jaime-sese/AP%20Barcelona/AP-Barcelona-05_t4dj1r.jpg",
    text: "8",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944392/jaime-sese/B-O-cophenague/B_O-Copenhague-04_w96mv2.jpg",
    text: "9",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944391/jaime-sese/B-O-cophenague/B_O-Copenhague-01_qihn01.jpg",
    text: "7",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944167/jaime-sese/AP%20Barcelona/AP-Barcelona-01_vnimwl.jpg",
    text: "7",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944167/jaime-sese/AP%20Barcelona/AP-Barcelona-04_pmmrxy.jpg",
    text: "7",
  },
  {
    link: "https://res.cloudinary.com/jmponz/image/upload/v1674944166/jaime-sese/AP%20Barcelona/AP-Barcelona-02_o3tgcg.jpg",
    text: "7",
  },
];
